@use '_modules' as *;

markets-summary {
  --background-colour: #{$blue};
  background-color: var(--background-colour);

  .introduction {
    position: relative;
    z-index: 999;
    text-align: center;
    margin-bottom: $spacer;
  }

  tabbed-content[data-style='1'] {
    z-index: 1;
    grid-gap: $gutter2;
    .tab-content {
      grid-auto-rows: initial;
      position: initial;
    }
    --side-nav-width: minmax(200px, 300px);
    nav[role='tablist'] {
      position: relative !important;
      top: 0 !important;
      z-index: 999;
      button {
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.4;
        svg {
          color: $red;
        }
        + button {
          border-top-color: rgba(white, 0.1);
        }
        &[aria-selected='true'] {
          color: $red;
        }
      }
    }
  }

  @include break-out(1000) {
    .introduction {
      text-align: left;
      padding-left: 20vw;
    }
    tabbed-content[data-style='1'] {
      padding-left: 20vw;

      details {
        .tab-content {
          div.text > picture.image {
            position: absolute;
            top: 0;
            height: 100%;
            width: 40vw;
            left: -50vw;
            margin-left: 50%;
            z-index: 1;
            animation: fadeInImage 0.3s;
            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 50%;
              background-image: linear-gradient(to left, $primary 25%, transparent);
              z-index: 2;
            }
            article {
              z-index: 3;
            }
          }
        }
      }
    }
  }

  article {
    display: grid;
    // display:none;
    grid-template-rows: 1fr 102px;
    grid-gap: $gutter2;
    height: 100%;
    position: relative;
    z-index: 1;
    picture.image {
      grid-row: 1 / span 2;
    }
    .text {
      > h2 {
        font-size: inherit;
        line-height: inherit;
        @include font-montserrat(bold);
      }
    }

    .cta-list {
      grid-column: 3;
      @include list-ul(white, $red);
      li {
        color: white;
      }
      ~ [role='navigation'] {
        grid-column: 2 / span 2;
      }
    }
  }

  @include break-in(1300) {
    // background-color:initial;

    &:before {
      border-top: 1px solid rgba(white, 0.1);
      background-color: $blue;
    }

    article {
      .image {
        display: none;
      }
      [role='navigation'],
      .text {
        grid-column: 1 / -1;
      }
    }
  }

  @include break-in(1000) {
    // tabbed-content {
    //   // display:flex;
    //   // flex-direction: column;
    //   grid-gap: $gap0;
    // }
    // .introduction {
    //   margin-bottom:$gap;
    // }
    // nav { display:none; }
    tabbed-content.markets.allow-breakpoint-restyling {
      details {
        display: block;
        position: relative;
        width: 100%;
        grid-column: 1 / -1;
        &[open] summary {
          background-color: $blue3;
        }
        summary {
          display: block;
          border: 1px solid rgba(white, 0.1);
        }
        .tab-content {
          background-color: initial !important;
          border-color: #444885;
        }
      }
    }
  }

  background-image {
    pointer-events: none;
    z-index: 0;
    @include position-expanded();
    opacity: 0.2;
    overflow: hidden;
    img {
      @include image-srcset();
      width: 100%;
      // width:100%;
      // @include position-expanded();
      position: absolute;
    }
    &::after {
      content: '';
      @include position-expanded();
      background: radial-gradient(closest-side, transparent, var(--background-colour));
    }
  }

  [role='navigation'] {
    display: grid;
    height: 100px;
    align-self: end;
    grid-column: 2 / -1;
    border-top: 1px solid rgba(white, 0.1);
    border-bottom: 1px solid rgba(white, 0.1);
    grid-gap: $gap;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-transform: uppercase;
      font-size: font-size(14px);
      font-weight: bold;
      svg {
        margin-right: $gap;
        width: 2.2em;
        fill: $red;
      }
      &:nth-of-type(2) svg {
        width: 3em;
      }
    }
  }

  @include break-in(small) {
    article {
      display: flex !important;
      flex-direction: column;
    }
    [role='navigation'] {
      display: flex !important;
      box-sizing: border-box;
      width: 100%;
      margin-top: $gap;
      flex-direction: column;
      padding: $gap $gap 0 $gap;
      height: initial;
      border-bottom: none;
      a {
        justify-content: flex-start;
        text-decoration: none !important;
        + a {
          margin-top: $gap;
        }
      }
    }
  }
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
