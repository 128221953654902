markets-summary {
  --background-colour: #131f6b;
  background-color: var(--background-colour);
}
markets-summary .introduction {
  position: relative;
  z-index: 999;
  text-align: center;
  margin-bottom: var(--spacer1);
}
markets-summary tabbed-content[data-style="1"] {
  z-index: 1;
  grid-gap: var(--gutter2);
  --side-nav-width: minmax(200px, 300px);
}
markets-summary tabbed-content[data-style="1"] .tab-content {
  grid-auto-rows: initial;
  position: initial;
}
markets-summary tabbed-content[data-style="1"] nav[role=tablist] {
  position: relative !important;
  top: 0 !important;
  z-index: 999;
}
markets-summary tabbed-content[data-style="1"] nav[role=tablist] button {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
}
markets-summary tabbed-content[data-style="1"] nav[role=tablist] button svg {
  color: #DF3035;
}
markets-summary tabbed-content[data-style="1"] nav[role=tablist] button + button {
  border-top-color: rgba(255, 255, 255, 0.1);
}
markets-summary tabbed-content[data-style="1"] nav[role=tablist] button[aria-selected=true] {
  color: #DF3035;
}
@media (min-width: 1001px) {
  markets-summary .introduction {
    text-align: left;
    padding-left: 20vw;
  }
  markets-summary tabbed-content[data-style="1"] {
    padding-left: 20vw;
  }
  markets-summary tabbed-content[data-style="1"] details .tab-content div.text > picture.image {
    position: absolute;
    top: 0;
    height: 100%;
    width: 40vw;
    left: -50vw;
    margin-left: 50%;
    z-index: 1;
    animation: fadeInImage 0.3s;
  }
  markets-summary tabbed-content[data-style="1"] details .tab-content div.text > picture.image::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-image: linear-gradient(to left, var(--primary, #131f6b) 25%, transparent);
    z-index: 2;
  }
  markets-summary tabbed-content[data-style="1"] details .tab-content div.text > picture.image article {
    z-index: 3;
  }
}
markets-summary article {
  display: grid;
  grid-template-rows: 1fr 102px;
  grid-gap: var(--gutter2);
  height: 100%;
  position: relative;
  z-index: 1;
}
markets-summary article picture.image {
  grid-row: 1/span 2;
}
markets-summary article .text > h2 {
  font-size: inherit;
  line-height: inherit;
  font-family: montserrat, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
markets-summary article .cta-list {
  grid-column: 3;
}
markets-summary article .cta-list > li::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='white' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='%23DF3035' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
}
markets-summary article .cta-list > li {
  position: relative;
  padding-left: calc(var(--list-style-size, 1em) * 1.8);
}
markets-summary article .cta-list > li + li {
  margin-top: var(--line-style-spacing, var(--gap));
}
markets-summary article .cta-list > li::before {
  display: inline-block;
  top: 0.1em;
  left: 0;
  position: absolute;
  width: var(--list-style-size, 1em);
  height: var(--list-style-size, 1em);
}
markets-summary article .cta-list li {
  color: white;
}
markets-summary article .cta-list ~ [role=navigation] {
  grid-column: 2/span 2;
}
@media (max-width: 1300px) {
  markets-summary:before {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #131f6b;
  }
  markets-summary article .image {
    display: none;
  }
  markets-summary article [role=navigation],
  markets-summary article .text {
    grid-column: 1/-1;
  }
}
@media (max-width: 1000px) {
  markets-summary tabbed-content.markets.allow-breakpoint-restyling details {
    display: block;
    position: relative;
    width: 100%;
    grid-column: 1/-1;
  }
  markets-summary tabbed-content.markets.allow-breakpoint-restyling details[open] summary {
    background-color: #3d3f7f;
  }
  markets-summary tabbed-content.markets.allow-breakpoint-restyling details summary {
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  markets-summary tabbed-content.markets.allow-breakpoint-restyling details .tab-content {
    background-color: initial !important;
    border-color: #444885;
  }
}
markets-summary background-image {
  pointer-events: none;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  overflow: hidden;
}
markets-summary background-image img {
  display: block;
  background-position: var(--position, center 33%);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  width: 100%;
  position: absolute;
}
markets-summary background-image img img {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: var(--image-position, center 33.33%);
  width: var(--image-size, var(--image-width-size, 100%));
  height: var(--image-size, var(--image-height-size, 100%));
  max-width: var(--image-size, var(--image-width-size, 100%));
  max-height: var(--image-size, var(--image-height-size, 100%));
  top: 0;
  left: 0;
}
markets-summary background-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(closest-side, transparent, var(--background-colour));
}
markets-summary [role=navigation] {
  display: grid;
  height: 100px;
  align-self: end;
  grid-column: 2/-1;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  grid-gap: var(--gap);
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
markets-summary [role=navigation] a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: bold;
}
markets-summary [role=navigation] a svg {
  margin-right: var(--gap);
  width: 2.2em;
  fill: #DF3035;
}
markets-summary [role=navigation] a:nth-of-type(2) svg {
  width: 3em;
}
@media (max-width: 480px) {
  markets-summary article {
    display: flex !important;
    flex-direction: column;
  }
  markets-summary [role=navigation] {
    display: flex !important;
    box-sizing: border-box;
    width: 100%;
    margin-top: var(--gap);
    flex-direction: column;
    padding: var(--gap) var(--gap) 0 var(--gap);
    height: initial;
    border-bottom: none;
  }
  markets-summary [role=navigation] a {
    justify-content: flex-start;
    text-decoration: none !important;
  }
  markets-summary [role=navigation] a + a {
    margin-top: var(--gap);
  }
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}